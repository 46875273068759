<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card tile class="pb-4">
          <v-card-title>
            Bestellungen
          </v-card-title>
          <!-- @click:row="(val, item) => { item.expand(!item.isExpanded) }" -->
          <v-data-table :headers="cols_orders" :items="$db.shop.myorders" item-key="ID" :items-per-page="-1" dense :hide-default-footer="true" show-expand single-expand>
              <template v-slot:item.ID="{ item }" >
                <span :class="item.status == 'cancelled' ? 'text-decoration-line-through red--text' : ''">#AXZ{{new Date(item.created).getTime()+(new Array(4).join('0')+item.ID).slice(-4)}}</span>
              </template>
              <template v-slot:item.created="{ item }">
                {{UTCtoLocal(item.created)}}
              </template>
              <template v-slot:item.total="{ item }">
                {{item.total}}€
              </template>
              <template v-slot:item.status="{ item }">
                {{statusDE[item.status]}}
              </template>
              <template v-slot:item.actions="{ item }">
                  <v-icon small class="ml-2" @click="cancelOrder(item)" :disabled="!(item.status == 'placed' || item.status == 'payed')">fas fa-ban</v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-6 pb-2">
                  <v-data-table :headers="cols_order_items" :items="item.items" item-key="item" :items-per-page="-1" dense :hide-default-footer="true">
                    <template v-slot:item.title="{ item }">
                      {{item.title}} {{item.variant}}
                    </template>
                    <template v-slot:item.price="{ item }">
                      {{item.price}}€
                    </template>
                    <template v-slot:item.total="{ item }">
                      {{item.total}}€
                    </template>
                  </v-data-table>
                </td>
              </template>
              <!--template v-slot:footer>
                  <v-row justify="center">
                      <v-col class="text-center"><v-icon small left>fas fa-eye</v-icon> = Online</v-col>
                      <v-col class="text-center"><v-icon small left color="grey">fas fa-eye-slash</v-icon> = Offline</v-col>
                      <v-col class="text-center"><v-icon small left color="grey">fas fa-lock</v-icon> = Intern</v-col>
                      <v-col class="text-center"><v-icon small left>fas fa-unlock</v-icon> = Extern</v-col>
                  </v-row>
              </template-->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card tile>
          <v-card-title>
            Shop
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-alert border="left" colored-border type="info" elevation="5" color="accent" prominent tile text>
                  Dieser "Shop" dient lediglich zur Sammlung von Bestellungsanfragen und <b>nicht</b> zum eigentlichen bestellen.<br>
                  Alle Zahlungen werden separat per <b>PayPal-Moneypool</b> getätigt. (Link: <a href="https://paypal.me/pools/c/8wyCb3U8IC" target="_blank">https://paypal.me/pools/c/8wyCb3U8IC</a>)<br>
                  <b>Bitte gebt bei der Zahlung die Bestellnummer aus der "Bestellungen"-Tabelle mit an, andernfalls ist es nicht möglich die Zahlung(en) zuzuordnen!</b><br>
                  <br>
                  Wir bitten euch eure gewünschte(n) Bestellung(en) bis zum <b>21.02.2021</b> einzureichen.<br>
                  Danach geben wir euch bis zum <b>28.02.2021</b> Zeit die erforderlichen Zahlungen zu tätigen.<br>
                  Sollte diese nicht zum Stichtag eingegangen sein werden wir eure Bestellung abbrechen.<br>
                  <br>
                  Solange die Sammelbestellung noch nicht in Auftrag gegeben wurde, könnt ihr eure Bestellung(en) noch abbrechen/stornieren.
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col>
                <v-expansion-panels tile>
                  <v-expansion-panel>
                    <v-expansion-panel-header color="box_accent">
                      Manatee Jersey Sizing-Chart
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="box_accent">
                      <v-img class="my-4" src="@/assets/manatee_sizing.png" contain height="280px" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col>
                <v-container grid-list-xl>
                  <v-layout wrap justify-space-around>
                      <v-flex shrink v-for="item in $db.shop.items" :key="item.ID">
                        <v-card tile color="box_accent" width="340px" elevation="5">
                          <v-card-title>
                            {{item.title}}
                          </v-card-title>
                          <axz-img :id="item.image" width="340px" height="200px" contain />
                          <v-card-actions>
                            <v-container>
                              <v-row justify="end" align="center">
                                <v-chip-group v-model="item.variant" active-class="accent--text" center-active mandatory>
                                  <v-chip v-for="(variant, i) in item.variants" :key="i" :value="variant" outlined>
                                    {{variant.name}}
                                  </v-chip>
                                </v-chip-group>
                              </v-row>
                              <v-row v-if="item.variant != null">
                                <v-col>
                                  <v-slider v-model="item.count" color="accent" :label="`${(parseFloat(item.variant.price) * (item.count != null ? item.count : 0)).toFixed(2)}€`" :hint="`Einzelpreis: ${parseFloat(item.variant.price).toFixed(2)}€`" min="0" max="5" step="1" ticks="always" tick-size="4" thumb-label="always" persistent-hint inverse-label></v-slider>
                                </v-col>
                              </v-row>
                              <v-row v-if="item.infoName != null && item.count > 0">
                                <v-col>
                                  <v-text-field v-model="item.info" :label="item.infoName" dense>
                                    <template v-slot:label>
                                      <span class="text-caption">{{item.infoName}}</span>
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-actions>
                        </v-card>
                      </v-flex>
                  </v-layout>
                </v-container>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col>
                <v-data-table :headers="cols_card" :items="orderItems" item-key="ID" :items-per-page="-1" dense :hide-default-footer="true">
                  <template v-slot:item.title="{ item }">
                    {{item.title}} {{item.variant}} {{item.info != null ? `(Zusatz: "${item.info}")`:''}}
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{item.price.toFixed(2)}}€
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{item.total.toFixed(2)}}€
                  </template>
                  <template v-slot:footer>
                    <v-container>
                      <v-row justify="end" align="end">
                          <v-col class="text-end">Gesamt: {{orderTotal.toFixed(2)}}€</v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-card-actions v-if="false">
              <v-row justify="center" align="center">
                <v-col class="text-center">
                  <v-btn tile color="green" @click="createOrder()" :disabled="orderTotal == 0">Bestellung abschicken</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      statusDE: {
        placed: 'aufgegeben',
        payed: 'bezahlt',
        ordered: 'bestellt',
        shipped: 'versandt',
        delivered: 'geliefert',
        cancelled: 'storniert'
      },
      cols_orders: [
        { text: '# Bestellnummer', value: 'ID' },
        { text: 'Erstellt am', value: 'created' },
        { text: 'Artikel', value: 'totalcount' },
        { text: 'Preis', value: 'total' },
        { text: 'Status', value: 'status' },
        { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      cols_order_items: [
        { text: 'Artikel', value: 'title', align: 'start', sortable: false},
        { text: 'Zusatz', value: 'info', align: 'start', sortable: false},
        { text: 'Einzelpreis', value: 'price', align: 'end' },
        { text: 'Anzahl', value: 'count', align: 'end' },
        { text: 'Preis', value: 'total', align: 'end' },
      ],
      cols_card: [
        //{ text: 'ID', value: 'ID' },
        { text: 'Artikel', value: 'title', align: 'start', sortable: false},
        //{ text: 'Zusatz', value: 'info', align: 'start', sortable: false},
        { text: 'Einzelpreis', value: 'price', align: 'end' },
        { text: 'Preis', value: 'total', align: 'end' },
      ],
    }
  },
  computed: {
    orderItems() {
      var items = [];
      this.$db.shop.items.forEach(item => {
        if(item.count != null && item.count > 0)
          items.push({ 
            ID: item.ID, 
            title: item.title, 
            count: item.count, 
            price: parseFloat(item.variant.price), 
            total: (parseFloat(item.variant.price) * item.count), 
            variant: item.variant.name, 
            info: item.infoName != null ? item.info : null,
          });
      });
      return items;
    },
    orderTotal() {
      var total = 0;
      this.orderItems.forEach(item => {
        total += item.total;
      });
      return total;
    }
  },
  methods: {
    createOrder() {
      this.$db.shop.createOrder(this.orderItems).then(() => {
        this.$db.shop.loadMyOrders();
        this.$db.shop.loadItems();
      });
    },
    cancelOrder(item) {
      this.$db.shop.cancelOrder(item.ID).then(() => {
        this.$db.shop.loadMyOrders();
      });
    }
  },
  mounted(){
    this.$db.shop.loadMyOrders();
    this.$db.shop.loadItems();
  }
}
</script>
